// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-applications-js": () => import("./../../../src/pages/products/applications.js" /* webpackChunkName: "component---src-pages-products-applications-js" */),
  "component---src-pages-products-digital-infrastructure-js": () => import("./../../../src/pages/products/digital-infrastructure.js" /* webpackChunkName: "component---src-pages-products-digital-infrastructure-js" */),
  "component---src-pages-sap-js": () => import("./../../../src/pages/sap.js" /* webpackChunkName: "component---src-pages-sap-js" */),
  "component---src-pages-success-stories-achieving-business-agility-js": () => import("./../../../src/pages/success-stories/achieving-business-agility.js" /* webpackChunkName: "component---src-pages-success-stories-achieving-business-agility-js" */),
  "component---src-pages-success-stories-business-process-optimisation-js": () => import("./../../../src/pages/success-stories/business-process-optimisation.js" /* webpackChunkName: "component---src-pages-success-stories-business-process-optimisation-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-success-stories-redefining-travel-js": () => import("./../../../src/pages/success-stories/redefining-travel.js" /* webpackChunkName: "component---src-pages-success-stories-redefining-travel-js" */),
  "component---src-pages-success-stories-strategic-hr-js": () => import("./../../../src/pages/success-stories/strategic-hr.js" /* webpackChunkName: "component---src-pages-success-stories-strategic-hr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

